//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "FAQS",
  data() {
    return {
      faqs: [
        {
          title: "هل أحتاج إضافة المنتجات والأقسام من جديد في التطبيق؟",
          content:
            "لا، لا يلزمك ذلك، فالتطبيق يرتبط بالمتجر مباشرة.",
        },
        {
          title: "هل يتضمن التطبيق أي تحديثات أو مزايا جديدة؟",
          content:
            "نعم، ستتم إضافة ميزات وخصائص جديدة بانتظام، وسيتم تحديث التطبيق تلقائياً عند إضافة أي خاصية أو ميزة جديدة",
        },
        {
          title: "كم يستغرق وقت تجهيز التطبيق؟",
          content:
            "يتراوح وقت تجهيز التطبيق بين 2 إلى 7 أيام عمل بعد إدخال بيانات التطبيق من قِبل العميل، ولكن يجب ملاحظة أن ذلك لا يشمل إنشاء حساب الشركة الخاص بالتاجر على منصة أبل وتفعيله، والتي تستغرق عادةً ما بين أسبوعين إلى شهر.",
        },
        {
          title: "هل يمكن تعديل تصميم التطبيق فيما بعد؟ وهل يتطلب ذلك دفع رسوم إضافية؟",
          content: "نعم، يمكن تعديل تصميم التطبيق فيما بعد من خلال لوحة التحكم الخاصة بمتجرك، وذلك بالدخول إلى تبويب تصميم التطبيق وإجراء التعديلات اللازمة، دون الحاجة لدفع أي رسوم إضافية.",
        },
      ],
    };
  },
};
